import { Grid, Box, Typography, Link } from '@material-ui/core';
import * as React from 'react';
import useStyles from './styles/BlogItem';
import { formatDate } from '../../../../utils/date';

interface ComponentProps {
  title: string;
  url: string;
  coverImageUrl: string;
  publishDate: Date;
}

function BlogItem({ title, url, coverImageUrl, publishDate }: ComponentProps) {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={3} className={classes.p10}>
      <Link href={url} className={classes.link}>
        {coverImageUrl ? (
          <img
            className={classes.coverImage}
            src={coverImageUrl}
            alt="Cover image"
            width="100%"
          />
        ) : (
          <div
            style={{
              width: '100%',
              height: 223,
              backgroundColor: '#212121',
            }}
          />
        )}
        <Box className={classes.info}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography className={classes.time}>
            {formatDate(publishDate)}
          </Typography>
        </Box>
      </Link>
    </Grid>
  );
}

export default BlogItem;
