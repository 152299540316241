import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import pubFetcher from '../utils/fetcher';

type FetcherResponse = Array<{
  title: string;
  publishDate: string;
  slug: string;
  coverImage: {
    url: string;
  };
}>;

export default function useLatestBlogPosts({
  count,
  queryConfig,
}: {
  count: number;
  queryConfig?: UseQueryOptions<any>;
}) {
  const url = `${process.env.GATSBY_BLOG_API_HOST}/posts/latest?count=${count}`;

  return useQuery<FetcherResponse, Error>(
    [url],
    () => pubFetcher(url),
    queryConfig
  );
}
