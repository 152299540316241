import { Grid } from '@material-ui/core';
import * as React from 'react';
import DiscoverOurTopCitiesItem from './DiscoverOurTopCitiesItem';
import useStyles from './styles/DiscoverOurTopCitiesContent';
import cityImages from '../../../../config/cityImageData/city-images.json';

const TOP_CITIES = [
  'London',
  'Croydon',
  'Harrow',
  'Ilford',
  'Southall',
  'Twickenham',
  'Manchester',
  'Liverpool',
  'Birmingham',
  'Sheffield',
  'Leeds',
  'Bristol',
];

function DiscoverOurTopCitiesContent() {
  const classes = useStyles();

  const topCitiesImageData = TOP_CITIES.map((name) =>
    cityImages.find((d) => d.name === name)
  ).filter((d) => d);

  return (
    <Grid container className={classes.row}>
      {topCitiesImageData.map((d) => (
        <DiscoverOurTopCitiesItem key={d!.name} data={d!} />
      ))}
    </Grid>
  );
}

export default DiscoverOurTopCitiesContent;
