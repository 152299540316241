import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import useStyles from './styles';
import { ConnectedPropertiesSearchForm } from '../../../widgets';
import useAutocompleteServices from '../../../../api/googleMaps/gcpPlaces/hooks/useAutocompleteServices';

function HeroSearch() {
  const classes = useStyles();

  const { autocompleteService, placesService } = useAutocompleteServices({
    googleMapsAttributeElementId: 'google-maps-att-el',
  });

  return (
    <Box className={classes.splashSection}>
      <Box className={classes.splashSectionContentWrapper}>
        <Box className={classes.splashSectionContent}>
          <Box className={classes.mottoCtn}>
            <Typography
              variant="h4"
              noWrap
              align="center"
              className={classes.titleBaner}
            >
              Never Move Again
            </Typography>
            <Typography className={classes.desBaner}>
              Find the best homes to rent
            </Typography>
          </Box>
          <Box className={classes.searchBoxCtn}>
            <ConnectedPropertiesSearchForm
              autocompleteService={autocompleteService}
              placesService={placesService}
              search=""
              redirectPathname="/properties"
              isHeroSearch
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default HeroSearch;
