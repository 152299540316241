import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    linkAboutUs: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      display: 'inline-block',
      padding: `${theme.spacing(1.8)}px ${theme.spacing(9)}px`,
      '&:hover': {
        backgroundColor: 'red',
      },
    },
    container: {
      maxWidth: theme.custom.container1460,
      margin: 'auto',
      padding: 0,
    },
    whyTitle: {
      fontSize: '2rem',
      textTransform: 'uppercase',
      color: theme.palette.gray.contrastText,
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
  })
);
