import * as React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { Link } from '../../../atoms';
import SpecialStepperHorizontal, {
  SpecialStep,
} from './SpecialStepperHorizontal';
import BestQuality from '../../../../images/iconsV2/large/Harmony/HA_4_bestquality.svg';
import TrustedLandLord from '../../../../images/iconsV2/large/Harmony/HA_5_trustedlandlord.svg';
import OnsiteManagement from '../../../../images/iconsV2/large/Harmony/HA_6_onsitemanagement.svg';
import useStyles from './styles';

export default function WhyRentWithUs() {
  const classes = useStyles();
  const steps: SpecialStep[] = [
    {
      header: 'High quality',
      body: 'New builds, high quality with regular maintenance',
      icon: BestQuality,
    },
    {
      header: 'Fully trusted landlords',
      body: 'Large-scale and professional, committed to customer satisfaction',
      icon: TrustedLandLord,
    },
    {
      header: 'Onsite management',
      body: 'All issues are sorted ASAP at no additional cost',
      icon: OnsiteManagement,
    },
  ];
  return (
    <Box
      p="50px 15px 80px 15px"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Container className={classes.container}>
        <Box pb={3}>
          <Typography variant="h4" className={classes.whyTitle}>
            Why Build To Rent
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignContent="stretch">
          <SpecialStepperHorizontal steps={steps} />
        </Box>
        <Box
          pt={6}
          display="flex"
          justifyContent="center"
          alignContent="stretch"
        >
          <Typography variant="h6">
            <Link to="/about">
              <span className={classes.linkAboutUs}>About Us</span>
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
