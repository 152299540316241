import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    row: {
      margin: '0 -10px',
      width: 'auto',
    },
  })
);
