import { Box, Container, Typography } from '@material-ui/core';
import * as React from 'react';
import { PopularPropertiesTabs } from '../../../widgets';
import useStyles from './styles';

const CITIES = [
  'London',
  'Manchester',
  'Sheffield',
  'Birmingham',
  'Liverpool',
  'Leeds',
  'Oldham',
];

export default function PopularProperties() {
  const classes = useStyles();
  return (
    <Box p="70px 15px" className={classes.bgGray}>
      <Container className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          Popular properties
        </Typography>
        <PopularPropertiesTabs cities={CITIES} />
      </Container>
    </Box>
  );
}
