import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    p10: {
      padding: 10,
      [theme.breakpoints.down('sm')]: {
        padding: 5,
      },
    },
    bgOveray: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: '0.35s',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
    },
    relative: {
      position: 'relative',
      display: 'block',
      height: 200,
      overflow: 'hidden',
      '& img': {
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        height: 100,
      },
    },
    title: {
      color: theme.palette.primary.contrastText,
      fontSize: '1.875rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.3rem',
      },
    },
  })
);
