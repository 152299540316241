import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    bgGray: {
      backgroundColor: '#F3F3F3',
      width: '100%',
    },
    container: {
      maxWidth: theme.custom.container1460,
      margin: 'auto',
      padding: 0,
    },
    title: {
      fontSize: '2rem',
      textTransform: 'uppercase',
      color: theme.palette.gray.contrastText,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: 30,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
  })
);
