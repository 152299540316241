import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    row: {
      margin: '0 -10px',
      width: 'auto',
    },
    p10: {
      padding: 10,
    },
    pr15: {
      paddingRight: 15,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: theme.palette.gray.contrastText,
      [theme.breakpoints.down(1350)]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.down(1200)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: '1.125rem',
      },
    },
    des: {
      fontSize: '1.25rem',
      color: theme.palette.gray.dark,
      [theme.breakpoints.down(1350)]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.down(1200)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: '1rem',
      },
    },
    icon: {
      height: 65,
      width: 65,
      color: theme.palette.gray.dark,
      [theme.breakpoints.up('sm')]: {
        marginRight: '1rem',
      },
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  })
);
