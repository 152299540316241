import { Box, Container, Typography } from '@material-ui/core';
import * as React from 'react';
import DiscoverOurTopCitiesContent from './DiscoverOurTopCitiesContent';
import useStyles from './styles';

function DiscoverOurTopCities() {
  const classes = useStyles();
  return (
    <Box p={'60px 15px 50px'} width={'100%'}>
      <Container className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          Discover top areas
        </Typography>
        <DiscoverOurTopCitiesContent />
      </Container>
    </Box>
  );
}

export default DiscoverOurTopCities;
