import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    p10: {
      padding: 10,
    },
    link: {
      backgroundColor: '#ffffff',
      display: 'block',
      height: '100%',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 140,
      padding: '10px 20px 15px 20px',
    },
    title: {
      flexGrow: 1,
      fontSize: '1.25rem',
      color: theme.palette.gray.contrastText,
      marginBottom: '0.5rem',
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(2),
      },
    },
    time: {
      fontSize: '1.125rem',
      color: '#B2B2B2',
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.875rem',
      },
    },
    coverImage: {
      height: 223,
      objectFit: 'cover',
      objectPosition: '100% 50%',
    },
  })
);
