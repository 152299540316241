import { Box, Container, Typography } from '@material-ui/core';
import * as React from 'react';
import HowWeContent from './HowWeContent';
import useStyles from './styles';

function HowWe() {
  const classes = useStyles();
  return (
    <Box p="60px 15px" className={classes.bgGray}>
      <Container className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          Our database
        </Typography>
        <HowWeContent />
      </Container>
    </Box>
  );
}

export default HowWe;
