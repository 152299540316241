import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    splashSection: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: theme.spacing(75),
      // This is the background image of the splash section. It only appears
      // in non-mobile view.
      backgroundImage: `url(https://res.cloudinary.com/rmnfrk9/image/upload/q_70/static/home_banner_qtdvpo.jpg)`,
      backgroundBlendMode: 'multiply, normal',
      backgroundPosition: 'bottom center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },
    },

    splashSectionContentWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: theme.custom.headerMaxWidth,
      width: '100%',
      padding: `${theme.spacing(4)}px 0`,
      [theme.breakpoints.up('mm')]: {
        // justifyContent: 'flex-start',
        // alignItems: 'flex-end',
        padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
      },
    },

    splashSectionContent: {
      maxWidth: theme.breakpoints.values.md,
      width: '100%',

      // [theme.breakpoints.up('mm')]: {
      //   position: 'absolute',
      //   top: theme.spacing(15),
      // },
    },

    mottoCtn: {
      marginBottom: theme.spacing(3.5),
    },

    // This contains the search bar and its various filters
    searchBoxCtn: {
      width: '100%',
      padding: theme.spacing(5),
      backgroundColor: '#fff',
      borderRadius: 4,
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      [theme.breakpoints.down('md')]: {
        padding: '25px 20px 10px 20px !important',
      },
    },

    grey: {
      backgroundColor: theme.palette.secondary.light,
    },

    desBaner: {
      textAlign: 'center',
      color: theme.palette.gray.dark,
      fontSize: '2rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
    titleBaner: {
      fontSize: 48,
      color: theme.palette.gray.contrastText,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.875rem',
      },
    },
  })
);
