import * as React from 'react';
import { Grid } from '@material-ui/core';
import VideoItem from './VideoItem';
import useStyles from './styles/VideoContent';
import useLatestBtrVideos from '../../../../api/marketing/useLatestBtrVideos';

export default function VideoContent() {
  const classes = useStyles();

  const { data: latestBtrVideos, isLoading: latestBtrVideosIsLoading } =
    useLatestBtrVideos({
      count: 3,
    });

  return (
    <Grid container className={classes.row}>
      {latestBtrVideos &&
        latestBtrVideos.map((btrVideo) => (
          <VideoItem
            key={btrVideo.videoId}
            videoId={btrVideo.videoId}
            thumbnailSrc={btrVideo.thumbnails.high.url}
            videoLength={btrVideo.videoLength}
            videoTitle={btrVideo.videoTitle}
            viewCount={btrVideo.viewCount}
          />
        ))}
    </Grid>
  );
}
