import * as React from 'react';
import { Grid } from '@material-ui/core';
import BlogItem from './BlogItem';
import useStyles from './styles/BlogContent';
import useLatestBlogPosts from '../../../../api/blog/useLatestBlogPosts';
import { getBlogPostUrlFromSlug } from '../../../../api/blog/utils';

export default function BlogContent() {
  const classes = useStyles();

  const { data: latestBlogPosts, isLoading: latestBlogPostsIsLoading } =
    useLatestBlogPosts({ count: 4 });

  return (
    <Grid container alignItems="stretch" className={classes.row}>
      {latestBlogPosts
        ? latestBlogPosts.map((post) => (
            <BlogItem
              key={post.slug}
              title={post.title}
              url={getBlogPostUrlFromSlug(post.slug)}
              coverImageUrl={post.coverImage ? post.coverImage.url : ''}
              publishDate={new Date(post.publishDate)}
            />
          ))
        : null}
    </Grid>
  );
}
