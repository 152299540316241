import { Grid, Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import useStyles from './styles/VideoItem';
import { pluralizeUsingS } from '../../../../utils/string';

interface ComponentProps {
  videoId: string;
  videoTitle: string;
  viewCount: number;
  videoLength: number;
  thumbnailSrc: string;
}

export default function VideoItem({
  videoId,
  videoTitle,
  viewCount,
  videoLength,
  thumbnailSrc,
}: ComponentProps) {
  const classes = useStyles();

  const [isOpen, setOpen] = useState(false);

  const getFormattedVideoLength = (): string => {
    const hours = Math.floor(videoLength / 3600);
    const timeHours = videoLength - hours * 3600;
    const seconds = Math.floor(timeHours / 60);
    const minutes = timeHours - seconds * 60;

    return hours !== 0 ? `${hours} : ` : '' + `${seconds} : ` + `${minutes}`;
  };

  return (
    <Grid item xs={12} sm={4} className={classes.videoItemCtn}>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setOpen(false)}
      />

      <Box onClick={() => setOpen(true)} className={classes.videoContainer}>
        <Box className={classes.relative}>
          <img src={thumbnailSrc} alt="video thumbnail" width="100%" />
          <Box className={classes.overlay}>
            <Box className={classes.animationIcon} />
            <PlayArrowIcon className={classes.icon} />
          </Box>
          <Box className={classes.time}>
            <Typography className={classes.textTime}>
              {getFormattedVideoLength()}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.videoInfoCtn}>
          <Typography variant="h5" className={classes.videoTitle}>
            {videoTitle}
          </Typography>
          <Typography className={classes.videoViewCount}>
            {viewCount} {pluralizeUsingS('view', viewCount)}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}
