import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { parseISO8601Duration } from './utils';

interface VideoSnippetThumbnail {
  url: string;
  width: number;
  height: number;
}

interface FetchVideoDetailsSuccessResponse {
  data: Array<{
    kind: string;
    etag: string;
    id: string;
    snippet: {
      publishedAt: string; // This is a Date string
      publishTime: string; // This is a Date string
      channelId: string;
      channelTitle: string;
      title: string;
      description: string;
      thumbnails: {
        default: VideoSnippetThumbnail;
        medium: VideoSnippetThumbnail;
        high: VideoSnippetThumbnail;
        maxres: VideoSnippetThumbnail;
      };
      liveBroadcastContent: string;
      tags: string[];
      categoryId: string;
      localized: {
        title: string;
        description: string;
      };
      defaultAudioLanguage: string;
    };
    contentDetails: {
      duration: string;
      dimension: string;
      definition: string;
      caption: string;
      licensedContent: boolean;
      contentRating: Record<string, string>;
      projection: string;
    };
    statistics: {
      viewCount: string;
      likeCount: string;
      dislikeCount: string;
      favoriteCount: string;
      commentCount: string;
    };
  }>;

  meta: {
    count: number;
  };
}

type FetchLatestBtrVideosSuccessResponse = Array<{
  videoId: string;
  videoTitle: string;
  viewCount: number;
  videoLength: number;
  thumbnails: {
    default: { url: string };
    medium: { url: string };
    high: { url: string };
    maxres: { url: string };
  };
}>;

export async function fetchLatestBtrVideos(
  url: string
): Promise<FetchLatestBtrVideosSuccessResponse> {
  // ---------- 1. Find latest video IDs ---------- //

  const videoListRes = await fetch(url, {
    headers: {
      Accept: 'application/json',
    },
  });

  if (!videoListRes.ok) {
    const errMsg = await videoListRes.text();
    throw new Error(
      `Could not fetch videos. Reason: ${videoListRes.status} - ${videoListRes.statusText} - ${errMsg}`
    );
  }

  const videoList: FetchVideoDetailsSuccessResponse = await videoListRes.json();

  return videoList.data.map((it) => ({
    videoId: it.id,
    videoTitle: it.snippet.title,
    viewCount: Number(it.statistics.viewCount),
    videoLength: parseISO8601Duration(it.contentDetails.duration),
    thumbnails: it.snippet.thumbnails,
  }));
}

interface UseFetchLatestBtrVideosProps {
  count: number;
  queryConfig?: UseQueryOptions<any>;
}

export default function useLatestBtrVideos({
  count,
  queryConfig,
}: UseFetchLatestBtrVideosProps) {
  const url = `${process.env.GATSBY_API_HOST}/api/marketing/youtube/latest/?count=${count}`;

  return useQuery<FetchLatestBtrVideosSuccessResponse, Error>(
    [url],
    () => fetchLatestBtrVideos(url),
    queryConfig
  );
}
