import { Box, Container, Typography, Button } from '@material-ui/core';
import * as React from 'react';
import BlogContent from './BlogContent';
import useStyles from './styles';

// ========== COMPONENTS ========== //

export default function Blog() {
  const classes = useStyles();

  return (
    <Box p="40px 15px" className={classes.bgGray}>
      <Container className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          BLOG
        </Typography>

        <BlogContent />

        <Box pt={'50px'} pb={4} className={classes.center}>
          <Button
            variant="contained"
            className={classes.customBtn}
            href={`${process.env.GATSBY_BLOG_SITE_URL}`}
          >
            More Articles
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
