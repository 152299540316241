import * as React from 'react';
import { PageProps } from 'gatsby';
import { Box } from '@material-ui/core';
import SEO from '../components/atoms/SEO';
import GenericPage from '../components/layout/CommonLayout/GenericPage';
import DiscoverOurTopCities from '../components/pages/home/DiscoverOurTopCities';
import Blog from '../components/pages/home/Blog';
import Video from '../components/pages/home/Video';
import HowWe from '../components/pages/home/HowWe';
import WhyRentWithUs from '../components/pages/home/WhyRentWithUs';
import TalkToUs from '../components/organisms/TalkToUs';
import HeroSearch from '../components/pages/home/HeroSearch';
import PopularProperties from '../components/pages/home/PopularProperties';
import useAnalyticsSessionData from '../hooks/useAnalyticsSessionData';

export default function Index({ location }: PageProps) {
  useAnalyticsSessionData();

  const { search } = location;

  // Sometimes we want to show the sign in dialog on redirection from other
  // places (e.g. blog site). Ideally we should have a separate sign in page.
  const urlSearchParams = new URLSearchParams(search);
  const showSignInDialog = urlSearchParams.get('showsignin') === 'true';

  return (
    <GenericPage authDialogOpen={showSignInDialog}>
      <SEO title="Never Move Again" />
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <HeroSearch />
        <WhyRentWithUs />
        <PopularProperties />
        <DiscoverOurTopCities />
        <Blog />
        <Video />
        <HowWe />
        <TalkToUs />
      </Box>
    </GenericPage>
  );
}
