import { Grid, Box, Typography } from '@material-ui/core';
import * as React from 'react';
import useStyles from './styles/HowWeContent';
import { Apartment, Home, Room } from '@material-ui/icons';

function HowWeContent() {
  const classes = useStyles();

  return (
    <Grid container className={classes.row}>
      <Grid item xs={12} sm={4} className={classes.p10}>
        <Box className={classes.info}>
          <Home className={classes.icon} />
          <Box className={classes.pr15}>
            <Typography variant="h6" className={classes.title}>
              100,000+ properties
            </Typography>
            <Typography className={classes.des}>
              Find your perfect home among thousands of high-quality options.
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.p10}>
        <Box className={classes.info}>
          <Apartment className={classes.icon} />
          <Box className={classes.pr15}>
            <Typography variant="h6" className={classes.title}>
              600 developments
            </Typography>
            <Typography className={classes.des}>
              Find your favourite amenities and enjoy the best living
              experience.
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.p10}>
        <Box className={classes.info}>
          <Room className={classes.icon} />
          <Box className={classes.pr15}>
            <Typography variant="h6" className={classes.title}>
              70 areas
            </Typography>
            <Typography className={classes.des}>
              Explore the new way to rent in the UK with our unique local area
              guides.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default HowWeContent;
