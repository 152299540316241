import * as React from 'react';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import SvgIconImg from '../../../atoms/SvgIconImg/SvgIconImg';

export interface SpecialStep {
  header: string;
  body?: string;
  icon?: string;
}

interface ComponentProps {
  steps: SpecialStep[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      backgroundColor: 'transparent',
      maxWidth: theme.custom.container1460,
      root: {
        width: '100%',
        maxWidth: theme.spacing(40),
        padding: theme.spacing(2),
      },
    },
    title: {
      marginBottom: theme.spacing(2),
      fontSize: '1.375rem',
      color: theme.palette.gray.contrastText,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.125rem',
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    des: {
      fontSize: '1.375rem',
      color: theme.palette.gray.dark,
      padding: '0 15px',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.125rem',
        padding: '0 5px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        padding: '0 0px',
      },
    },
    mt0: {
      marginTop: 0,
    },
    iconSize: {
      width: 172,
      height: 172,
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        width: 130,
        height: 130,
      },
    },
    root: {
      flexGrow: 1,
    },
    item: {
      textAlign: 'center',
    },
  })
);

export default function SpecialStepperHorizontal({ steps }: ComponentProps) {
  const classes = useStyles();

  const stepEls = steps.map(({ header, body, icon: Icon }) => {
    return (
      <Grid key={header} item className={classes.item} xs={12} sm={4}>
        <SvgIconImg
          className={classes.iconSize}
          src={Icon ?? ''}
          alt="service 1"
        />
        <Typography variant="h6" className={classes.title}>
          {header}
        </Typography>
        <Typography className={classes.des}>{body}</Typography>
      </Grid>
    );
  });

  return (
    <Box className={classes.root} bgcolor="transparent">
      <Grid container spacing={3}>
        {stepEls}
      </Grid>
    </Box>
  );
}
