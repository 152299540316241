import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    videoItemCtn: {
      padding: '.5rem',
    },

    videoContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      boxShadow: '0 0 20px rgba(0,0,0,0.24)',
      cursor: 'pointer',
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },

    relative: {
      position: 'relative',
    },

    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 5,
      right: 0,
      left: 0,
      background: 'rgba(0,0,0,0.4)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    animationIcon: {
      width: 72,
      height: 72,
      background: 'rgba(255,255,255,0.5)',
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      animation: '$pulse 1s ease-out infinite',
    },

    icon: {
      color: '#ffffff',
      fontSize: 60,
      position: 'absolute',
    },

    '@keyframes pulse': {
      '0%': {
        opacity: 1,
        transform: 'scale(1)',
      },
      '100%': {
        opacity: 0,
        transform: 'scale(1.3)',
      },
    },

    videoInfoCtn: {
      flexGrow: 1,
      position: 'relative',
      padding: '1rem 2rem 3rem 2rem',
      [theme.breakpoints.down('sm')]: {
        padding: '0.5rem 1rem 2.5rem 1rem',
      },
    },

    videoTitle: {
      fontSize: '1.25rem',
      color: theme.palette.gray.contrastText,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },

    videoViewCount: {
      position: 'absolute',
      bottom: '1rem',
      right: '2rem',
      textAlign: 'right',
      color: theme.palette.gray.dark,
      fontSize: '1.125rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },

    time: {
      position: 'absolute',
      top: '0.75rem',
      right: '1rem',
      background: 'rgba(0,0,0,0.75)',
      borderRadius: 4,
      padding: '0 1rem',
    },

    textTime: {
      color: '#ffffff',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
  })
);
