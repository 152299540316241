import { Box, Container, Typography } from '@material-ui/core';
import * as React from 'react';
import VideoContent from './VideoContent';
import useStyles from './styles';

// ========== COMPONENTS ========== //

function Video(props) {
  const classes = useStyles();
  return (
    <Box p="60px 15px" className={classes.bgWhite}>
      <Container className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          VIDEOS
        </Typography>
        <VideoContent />
      </Container>
    </Box>
  );
}

export default Video;
