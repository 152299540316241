import { Grid, Typography, Box, Link } from '@material-ui/core';
import * as React from 'react';
import useStyles from './styles/DiscoverOurTopCitiesItem';

interface ComponentProps {
  data: Record<string, string>;
}
function DiscoverOurTopCitiesItem({ data }: ComponentProps) {
  const classes = useStyles();
  return (
    <Grid item xs={6} sm={4} md={3} className={classes.p10}>
      <Link href={data.link} className={classes.relative}>
        <img src={data.img} alt="#" width="100%" />
        <Box className={classes.bgOveray}>
          <Typography variant="h5" className={classes.title}>
            {data.name}
          </Typography>
        </Box>
      </Link>
    </Grid>
  );
}

export default DiscoverOurTopCitiesItem;
